body {
    background-color: #11103d !important;
}

#login {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: 30%;
}
a.link {
    color: #00a8ac;
}
a.link:hover {
    color: #00a8ac;
    text-decoration: none;
}
.form-signin {
    margin: 0 auto;
    max-width: 330px;
    padding: 15px;
}
.form-signin .form-signin-heading, .form-signin .checkbox {
    margin-bottom: 10px;
}
.form-signin .checkbox {
    font-weight: normal;
}
.form-signin .form-control {
    box-sizing: border-box;
    font-size: 16px;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #d9d9d9;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
}
.form-signin input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 10px;
}
.form-signin .btn-default {
    background-color: #e1e7eb;
}
.form-signin .btn-primary {
    background-color: #00a8ac;
}
.form-signin .btn-primary:hover, .form-signin .btn-primary:focus {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075) inset, 0 0 30px rgba(102, 175, 233, 1) !important;
    background-color: #00a8ac;
    border-color: #00a8ac;
    color: #fff;
}
.form-signin .btn-default {
    background-color: #e84a62;
    border-color: #e84a62;
    color: white;
}
.form-signin .btn-default:hover, .form-signin .btn-default:focus {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075) inset, 0 0 30px rgba(102, 175, 233, 1) !important;
    background-color: #e84a62;
    border-color: #e84a62;
}
.form-control {
    border: 1px solid #9499a5 !important;
    color: #white !important;
}
.form-control:focus {
    box-shadow: 0 7px 6px rgba(0, 0, 0, 0.075) inset, 0 0 30px rgba(102, 175, 233, 0.6) !important;
}
.form-control::-moz-placeholder {
    color: #51586c;
    opacity: 1;
}
.center-block {
    width: 250px;
    height: 15%;
    margin-bottom: 2%;
}
